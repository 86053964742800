import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipStoreService } from '@penji/client-v3/membership/data-access';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProfileChangePlanComponent } from '@penji/client-v3/profiles/ui/profile-change-plan';

@Component({
  selector: 'penji-membership-name',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './membership-name.component.html',
  styleUrls: ['./membership-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipNameComponent {

  private readonly membershipStore = inject(MembershipStoreService);
  private readonly modal = inject(NzModalService);

  membership$ = this.membershipStore.list_sub_design$
  
  openChangePlan() {
    this.modal.create({
      nzCentered: true,
      nzContent: ProfileChangePlanComponent,
      nzBodyStyle: { padding: '0px', overflow: 'auto', background: '#fff'},
      nzWidth: '1024px',
      nzStyle: { top: '14px' },
      nzTitle: 'Change your plan',
      nzFooter: null,
      nzWrapClassName: 'modal-profile-change-plan [&_.ant-modal-title]:text-center'
    });
  }
}
