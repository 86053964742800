<ng-container *ngIf="{data: data$ | async, membership: membership$ | async} as vm">
  <div class="pb-4">
    <div id="list" class="relative">
      <ng-container *ngFor="let root of vm.data.root">
        <div class="pb-2 border-b border-b-gray-200 last-of-type:border-b-0 dark:border-b-gray-500">
          <p
            class="text-[16px] font-bold pb-1 pt-2 sticky title-category top-0 left-0 right-0 z-20 bg-white dark:bg-gray-700">
            {{ root.title }}</p>

          <ng-container *ngFor="let cate of vm.data[root.id]">
            <div (click)="selectCategory(cate.id)"
              class="flex justify-between items-center pl-3 pr-3 py-1.5 hover:bg-gray-100 dark:hover:bg-gray-400 hover:rounded cursor-pointer group last-of-type:border-b-0">
              <span class="flex items-center space-x-3 dark:[&_svg]:fill-gray-200 dark:[&_svg_path]:fill-gray-200">
                <span [innerHTML]="cate?.icon | safeHtml"></span>
                <span>{{cate?.title}}</span>
                <svg *ngIf="cate?.locked" width="21" height="21" viewBox="0 0 21 21" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.980469" y="0.5" width="20" height="20" rx="10" fill="#EAB308" />
                  <g clip-path="url(#clip0_7638_7176)">
                    <path
                      d="M11.3906 7.57031C11.6133 7.43359 11.7617 7.18555 11.7617 6.90625C11.7617 6.47461 11.4121 6.125 10.9805 6.125C10.5488 6.125 10.1992 6.47461 10.1992 6.90625C10.1992 7.1875 10.3477 7.43359 10.5703 7.57031L9.45117 9.80859C9.27344 10.1641 8.8125 10.2656 8.50195 10.0176L6.76172 8.625C6.85938 8.49414 6.91797 8.33203 6.91797 8.15625C6.91797 7.72461 6.56836 7.375 6.13672 7.375C5.70508 7.375 5.35547 7.72461 5.35547 8.15625C5.35547 8.58789 5.70508 8.9375 6.13672 8.9375H6.15039L7.04297 13.8477C7.15039 14.4414 7.66797 14.875 8.27344 14.875H13.6875C14.291 14.875 14.8086 14.4434 14.918 13.8477L15.8105 8.9375H15.8242C16.2559 8.9375 16.6055 8.58789 16.6055 8.15625C16.6055 7.72461 16.2559 7.375 15.8242 7.375C15.3926 7.375 15.043 7.72461 15.043 8.15625C15.043 8.33203 15.1016 8.49414 15.1992 8.625L13.459 10.0176C13.1484 10.2656 12.6875 10.1641 12.5098 9.80859L11.3906 7.57031Z"
                      fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_7638_7176">
                      <rect width="11.25" height="10" fill="white" transform="translate(5.35547 5.5)" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <ng-container
                *ngIf="cate?.locked && (!vm.membership || !vm.membership[0]?.metadata || vm.membership[0]?.metadata?.full_category!==1); else includes">
                <div class="flex items-center space-x-2">
                  <span
                    class="opacity-0 group-hover:opacity-100 w-fit flex bg-green-500 text-white text-sm px-2 py-1 rounded transition duration-300">
                    Upgrade plan
                  </span>
                  <span class="material-icons-outlined text-2xl text-gray-600">lock</span>
                </div>
              </ng-container>
              <ng-template #includes>
                <span class="material-icons-outlined hidden group-hover:flex text-[20px]">
                  chevron_right
                </span>
              </ng-template>
            </div>
          </ng-container>

        </div>
      </ng-container>
    </div>
  </div>
</ng-container>