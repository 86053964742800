<ng-container *ngIf="{auth_id: auth_id$ | async, membership: checkMembership$ | async} as vm">
    <div class="w-full" *ngIf="vm.membership?.owner_id === vm.auth_id; else access_deny">
        <div class="max-w-[480px] mx-auto">
            <ng-container
                *ngIf="{proration_preview: proration_preview$ | async, payment_source: payment_source$ | async} as py; else loading">

                <div class="w-full space-y-3">
                    <p class="text-lg text-black font-medium">Payment method</p>
                    <div *ngIf="py.payment_source && py.payment_source.success && py.payment_source.data; else denycard"
                        class="flex items-center justify-between space-x-4 border border-1 border-gray border-gray-200 rounded-lg p-4">
                        <div class="flex items-center space-x-4">
                            <span class="material-icons-outlined">credit_card</span>
                            <div
                                *ngIf="py.payment_source.data.default_source && py.payment_source.data.default_source.card">
                                <p class="flex space-x-2 items-center">
                                    <span class="text-black font-[600]">Visa ending in
                                        {{ py.payment_source.data.default_source.card.last4 }}</span>
                                </p>
                                <p class="text-gray-700 text-sm font-[400]">
                                    Expiry. {{ py.payment_source.data.default_source.card.exp_month }}/{{
                                    py.payment_source.data.default_source.card.exp_year
                                    }}
                                </p>
                            </div>
                            <div *ngIf="py.payment_source.data.card">
                                <p class="flex space-x-2 items-center">
                                    <span class="text-black font-[600]">Visa ending in
                                        {{ py.payment_source.data.card.last4 }}</span>
                                </p>
                                <p class="text-gray-700 text-sm font-[400]">
                                    Expiry. {{ py.payment_source.data.card.exp_month }}/{{
                                    py.payment_source.data.card.exp_year
                                    }}
                                </p>
                            </div>
                        </div>
                        <button (click)="changeCard(vm.membership?.team_id)" nz-button nzType="default" type="button"
                            class="bg-blue-600 hover:bg-transparent border-blue-600 text-white hover:border-blue-600 hover:text-blue-600">
                            Change
                        </button>
                    </div>
                    <ng-template #denycard>
                        <nz-alert nzType="warning" nzMessage="Warning"
                            nzDescription="You don't have permission to access." nzShowIcon></nz-alert>
                    </ng-template>
                </div>

                <div class="flex items-center justify-between space-x-4 border-b border-gray-200 py-4">
                    <p>Change to</p>
                    <p class="flex items-center space-x-3">
                        <span class="material-icons-outlined text-[#31A8FE] text-3xl"
                            *ngIf="plan.plan_package==='starter'">rocket_launch</span>
                        <span class="material-icons-outlined text-[#22B8CF] text-3xl"
                            *ngIf="plan.plan_package==='marketer'">insert_chart</span>
                        <span class="material-icons-outlined text-[#51CF66] text-3xl"
                            *ngIf="plan.plan_package==='agency'">domain</span>
                        <span class="text-lg font-bold text-black">{{plan.plan_name}} plan</span>
                    </p>
                </div>

                <div class="flex items-center justify-between space-x-4 border-b border-gray-200 py-4">
                    <p>Monthly membership</p>
                    <p>{{py.proration_preview?.price | currency}}</p>
                </div>

                <div class="flex items-center justify-between space-x-4 border-b border-gray-200 [&_p]:text-green-500 py-4">
                    <p>Total billed now</p>
                    <p>
                        {{
                        (confirm_disabled$ | async)?
                        'Loading...':
                        (py.proration_preview?.real_amount | currency)
                        }}
                    </p>
                </div>

                <div class="w-full space-y-3 [&_button]:rounded-l-none py-4">
                    <p class="text-lg text-black font-medium mb-0">Promo code</p>
                    <nz-input-group nzSearch [nzAddOnAfter]="applyButton">
                        <input type="text" nz-input placeholder="" [(ngModel)]="promo_code" />
                    </nz-input-group>
                    <ng-template #applyButton>
                        <button *ngIf="vm.membership && vm.membership.token && vm.membership.team_id"
                            (click)="checkPromoCode(vm.membership.token, vm.membership.team_id, vm.membership.list_sub_design[0].sub_id, plan.plan_id, promo_code)"
                            nz-button nzType="default"
                            [disabled]="(confirm_disabled$ | async)||promo_code===''">Apply</button>
                    </ng-template>
                    <button *ngIf="vm.membership && vm.membership.token"
                        (click)="confirmCheckout(vm.membership.token, vm.membership.list_sub_design[0].sub_id, plan.plan_id, promo_code)"
                        nz-button nzType="primary" nzBlock nzSize="large"
                        [disabled]="(confirm_disabled$ | async) || (!py.payment_source || !py.payment_source.data || !py.payment_source.data.card)">
                        Confirm
                    </button>
                </div>

                <div class="flex space-x-2 justify-center items-center text-green-500 py-4">
                    <span class="material-icons text-base">lock</span>
                    <p class="font-medium">Secure payment</p>
                </div>

            </ng-container>
            <ng-template #loading>
                <nz-skeleton [nzActive]="true" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
            </ng-template>
        </div>
    </div>
    <ng-template #access_deny>
        <nz-alert nzType="warning" nzMessage="Warning"
            nzDescription="You can't access this. Only the team owner has permission." nzShowIcon></nz-alert>
    </ng-template>
</ng-container>