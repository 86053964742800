<ng-container *ngIf="membership$ | async as membership; else loading">
    <ng-container *ngIf="membership[0]; else empty">
        <div class="w-full grid grid-cols-3 gap-6 px-6 pb-6 pt-3">
            <ng-container *ngFor="let item of list_all_plan | keyvalue">
                <ng-container *ngIf="plan_duration === item.value.plan_duration && item.value.plan_order">
                    <div
                        class="w-full rounded-lg border border-gray-200 {{(showmore$ | async)?'[&>div:not(:last-of-type)]:border-b [&>div:not(:last-of-type)]:border-gray-200':''}} {{item.value.plan_order===1?'order-first':item.value.plan_order===3?'order-last':''}}">
                        <div class="w-full px-6 py-4 space-y-6">
                            <div class="w-full space-y-3">
                                <div class="w-full flex items-center space-x-2">
                                    <span class="material-icons-outlined text-[#31A8FE] text-3xl"
                                        *ngIf="item.value.plan_package==='starter'">rocket_launch</span>
                                    <span class="material-icons-outlined text-[#22B8CF] text-3xl"
                                        *ngIf="item.value.plan_package==='marketer'">insert_chart</span>
                                    <span class="material-icons-outlined text-[#51CF66] text-3xl"
                                        *ngIf="item.value.plan_package==='agency'">domain</span>
                                    <span class="text-lg font-bold text-black">{{item.value.plan_name}}</span>
                                </div>
                                <div class="w-full flex items-center space-x-1">
                                    <span class="text-[36px] font-bold text-black"
                                        *ngIf="item.value.plan_base && item.value.plan_duration_month">
                                        {{(item.value.plan_base / item.value.plan_duration_month) | currency}}
                                    </span>
                                    <span class="text-base text-gray-600 font-medium">/month</span>
                                </div>
                                <div class="text-gray-700 text-sm font-normal">{{item.value.plan_subtitle}}</div>
                            </div>
                            <button (click)="openConfirmCheckout(item.value)" nz-button nzType="default" type="button"
                                nzBlock nzSize="large"
                                [disabled]="(((membership[0]['price_id']===item.value.plan_id)
                                || ((item.value.plan_package==='starter' && (membership[0]['product_name'].includes('pro') 
                                || membership[0]['product_name'].includes('Pro') || membership[0]['product_name'].includes('starter')))) 
                                || (item.value.plan_package==='marketer' && (membership[0]['product_name'].includes('marketer') || membership[0]['product_name'].includes('team'))) 
                                || (item.value.plan_package==='agency' && (membership[0]['product_name'].includes('agency') || membership[0]['product_name'].includes('daytime'))))
                                && (membership[0]['end_date'] && membership[0]['end_date']['seconds']*1000 >= today.getTime()))"
                                class="bg-blue-600 hover:bg-transparent border-blue-600 text-white hover:border-blue-600 hover:text-blue-600 my-6">
                                <ng-container
                                    *ngIf="(membership[0]['price_id']===item.value.plan_id 
                                    || ((item.value.plan_package==='starter' 
                                    && (membership[0]['product_name'].includes('pro') || membership[0]['product_name'].includes('Pro') || membership[0]['product_name'].includes('starter')))) 
                                    || (item.value.plan_package==='marketer' && (membership[0]['product_name'].includes('marketer') || membership[0]['product_name'].includes('team'))) 
                                    || (item.value.plan_package==='agency' && (membership[0]['product_name'].includes('agency') || membership[0]['product_name'].includes('daytime')))
                                    && (membership[0]['end_date'] && membership[0]['end_date']['seconds']*1000 >= today.getTime())); 
                                    else choose_plan">
                                    Current plan
                                </ng-container>
                                <ng-template #choose_plan>
                                    Choose {{item.value.plan_name}}
                                </ng-template>
                            </button>
                            <a (click)="clickShowMore(showmore$.getValue())"
                                class="w-full flex items-center space-x-1 text-gray-800 font-normal">
                                <span
                                    class="material-icons-outlined text-lg {{(showmore$ | async)?'rotate-180':''}}">keyboard_arrow_down</span>
                                <span class="text-sm">{{(showmore$ | async)?'Show less':'Show more'}}</span>
                            </a>
                        </div>
                        <div class="w-full px-6 py-4 space-y-6 {{(showmore$ | async)?'':'hidden'}}">
                            <penji-plan-description [title]="true" [creative_talent]="true" [features]="true"
                                [plan_package]="item.value.plan_package"
                                [plan_duration]="item.value.plan_duration"></penji-plan-description>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
<ng-template #empty>
    <nz-empty nzNotFoundContent="Not found current subscription"></nz-empty>
</ng-template>
<ng-template #loading>
    <nz-skeleton class="mb-6" [nzActive]="true" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
</ng-template>