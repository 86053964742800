<ng-container *ngIf="{membership:membership$ | async} as vm">
  <ng-container *ngIf="vm.membership && vm.membership[0] && vm.membership[0].product_name">
    <!-- Starter -->
    <div (click)="openChangePlan()" class="flex items-center gap-2 h-[38px] px-2 rounded-md shadow-md cursor-pointer hover:shadow-lg dark:bg-gray-600">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M6.71007 19.3102C6.43007 19.5902 4.54007 20.0702 4.54007 20.0702C4.54007 20.0702 5.01007 18.1902 5.30007 17.9002C5.47007 17.7102 5.72007 17.6002 6.00007 17.6002C6.19805 17.6005 6.39152 17.6593 6.55611 17.7694C6.72069 17.8794 6.84903 18.0357 6.92495 18.2186C7.00087 18.4014 7.02097 18.6026 6.98274 18.7969C6.9445 18.9912 6.84963 19.1698 6.71007 19.3102ZM7.41007 11.4302L5.50007 10.6102L7.47007 8.64018L8.91007 8.93018C8.34007 9.76018 7.83007 10.6302 7.41007 11.4302ZM13.9901 19.1002L13.1701 17.1902C13.9701 16.7702 14.8401 16.2602 15.6601 15.6902L15.9501 17.1302L13.9901 19.1002ZM19.9901 4.61018C19.9901 4.61018 16.4401 3.92018 11.7601 8.60018C10.4401 9.92018 9.36007 11.9802 9.03007 12.6402L11.9601 15.5702C12.6101 15.2502 14.6701 14.1702 16.0001 12.8402C20.6801 8.16018 19.9901 4.61018 19.9901 4.61018ZM15.0001 11.6002C13.9001 11.6002 13.0001 10.7002 13.0001 9.60018C13.0001 8.50018 13.9001 7.60018 15.0001 7.60018C16.1001 7.60018 17.0001 8.50018 17.0001 9.60018C17.0001 10.7002 16.1001 11.6002 15.0001 11.6002Z" fill="#31A8FE"></path>
        <path d="M6.00003 15.6002C5.17003 15.6002 4.42003 15.9402 3.88003 16.4802C2.70003 17.6602 2.00003 22.6002 2.00003 22.6002C2.00003 22.6002 6.94003 21.9002 8.12003 20.7202C8.54102 20.3014 8.82805 19.7669 8.9447 19.1847C9.06134 18.6024 9.00233 17.9957 8.77516 17.45C8.54799 16.9014 8.1629 16.4326 7.66879 16.1032C7.17468 15.7739 6.59385 15.5988 6.00003 15.6002ZM6.71003 19.3102C6.43003 19.5902 4.54003 20.0702 4.54003 20.0702C4.54003 20.0702 5.01003 18.1902 5.30003 17.9002C5.47003 17.7102 5.72003 17.6002 6.00003 17.6002C6.19801 17.6005 6.39148 17.6593 6.55607 17.7694C6.72065 17.8794 6.84899 18.0357 6.92491 18.2186C7.00083 18.4014 7.02094 18.6026 6.9827 18.7969C6.94446 18.9912 6.84959 19.1697 6.71003 19.3102ZM17.42 14.2502C23.78 7.89018 21.66 2.94018 21.66 2.94018C21.66 2.94018 16.71 0.820183 10.35 7.18018L7.86003 6.68018C7.53635 6.61803 7.2024 6.63563 6.88705 6.73146C6.57169 6.82729 6.28441 6.99546 6.05003 7.23018L2.00003 11.2902L7.00003 13.4302L11.17 17.6002L13.31 22.6002L17.36 18.5502C17.83 18.0802 18.04 17.4002 17.91 16.7402L17.42 14.2502ZM7.41003 11.4302L5.50003 10.6102L7.47003 8.64018L8.91003 8.93018C8.34003 9.76018 7.83003 10.6302 7.41003 11.4302ZM13.99 19.1002L13.17 17.1902C13.97 16.7702 14.84 16.2602 15.66 15.6902L15.95 17.1302L13.99 19.1002ZM16 12.8402C14.68 14.1602 12.62 15.2402 11.96 15.5702L9.03003 12.6402C9.35003 11.9902 10.43 9.93018 11.76 8.60018C16.44 3.92018 19.99 4.61018 19.99 4.61018C19.99 4.61018 20.68 8.16018 16 12.8402ZM15 11.6002C16.1 11.6002 17 10.7002 17 9.60018C17 8.50018 16.1 7.60018 15 7.60018C13.9 7.60018 13 8.50018 13 9.60018C13 10.7002 13.9 11.6002 15 11.6002Z" fill="#31A8FE"></path>
        </svg>
        <span class="font-semibold text-gray-600 dark:text-white">{{vm.membership[0].product_name}}</span>
    </div>
    <!-- Marketer -->
    <!-- <div class="flex items-center gap-2 h-[38px] px-2 rounded-md shadow-md cursor-pointer hover:shadow-lg dark:bg-gray-600">
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M19 5.60019H5V19.6002H19V5.60019ZM9 17.6002H7V10.6002H9V17.6002ZM13 17.6002H11V7.60019H13V17.6002ZM17 17.6002H15V13.6002H17V17.6002Z" fill="#22B8CF"></path>
        <path d="M3 5.60019V19.6002C3 20.7002 3.9 21.6002 5 21.6002H19C20.1 21.6002 21 20.7002 21 19.6002V5.60019C21 4.50019 20.1 3.60019 19 3.60019H5C3.9 3.60019 3 4.50019 3 5.60019ZM5 5.60019H19V19.6002H5V5.60019ZM7 10.6002H9V17.6002H7V10.6002ZM11 7.60019H13V17.6002H11V7.60019ZM15 13.6002H17V17.6002H15V13.6002Z" fill="#22B8CF"></path>
        </svg>
        <span class="font-semibold text-gray-600 dark:text-white">{{vm.membership[0].product_name}}</span>
    </div> -->
    <!-- Agency -->
    <!-- <div class="flex items-center gap-2 h-[38px] px-2 rounded-md shadow-md cursor-pointer hover:shadow-lg dark:bg-gray-600">
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M12.5001 11.6002H14.5001V13.6002H12.5001V15.6002H14.5001V17.6002H12.5001V19.6002H20.5001V9.60019H12.5001V11.6002ZM16.5001 11.6002H18.5001V13.6002H16.5001V11.6002ZM16.5001 15.6002H18.5001V17.6002H16.5001V15.6002Z" fill="#51CF66"></path>
        <path d="M12.5001 7.60019V3.60019H2.50012V21.6002H22.5001V7.60019H12.5001ZM6.50012 19.6002H4.50012V17.6002H6.50012V19.6002ZM6.50012 15.6002H4.50012V13.6002H6.50012V15.6002ZM6.50012 11.6002H4.50012V9.60019H6.50012V11.6002ZM6.50012 7.60019H4.50012V5.60019H6.50012V7.60019ZM10.5001 19.6002H8.50012V17.6002H10.5001V19.6002ZM10.5001 15.6002H8.50012V13.6002H10.5001V15.6002ZM10.5001 11.6002H8.50012V9.60019H10.5001V11.6002ZM10.5001 7.60019H8.50012V5.60019H10.5001V7.60019ZM20.5001 19.6002H12.5001V17.6002H14.5001V15.6002H12.5001V13.6002H14.5001V11.6002H12.5001V9.60019H20.5001V19.6002ZM16.5001 11.6002H18.5001V13.6002H16.5001V11.6002ZM16.5001 15.6002H18.5001V17.6002H16.5001V15.6002Z" fill="#51CF66"></path>
        </svg>
        <span class="font-semibold text-gray-600 dark:text-white">{{vm.membership[0].product_name}}</span>
    </div> -->
  </ng-container>
</ng-container>