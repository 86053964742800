<div class="w-full space-y-4">
    <div class="w-full grid grid-cols-{{grid}} gap-2" *ngIf="creative_talent">
        <p class="text-gray-900 font-semibold" *ngIf="title">Creative talents</p>
        <ng-container *ngIf="list_all_plan && plan_package && plan_duration">
            <p class="flex items-center space-x-2"
                *ngFor="let desc of list_all_plan[plan_package+'_'+plan_duration].plan_description">
                <span class="material-icons text-green-500 text-lg">check</span>
                <span class="text-gray-700 font-normal">{{desc}}</span>
            </p>
        </ng-container>
    </div>
    <div class="w-full grid grid-cols-{{grid}} gap-2" *ngIf="creative_services">
        <p class="text-gray-900 font-semibold" *ngIf="title">Creative services</p>
        <ng-container *ngIf="list_all_plan && plan_package && plan_duration">
            <p class="flex items-center space-x-2"
                *ngFor="let desc of list_all_plan[plan_package+'_'+plan_duration].plan_creative_services">
                <span class="material-icons text-green-500 text-lg">check</span>
                <span class="text-gray-700 font-normal">{{desc}}</span>
            </p>
        </ng-container>
    </div>
    <div class="w-full grid grid-cols-{{grid}} gap-2" *ngIf="features">
        <p class="text-gray-900 font-semibold" *ngIf="title">Features</p>
        <ng-container *ngIf="list_all_plan && plan_package && plan_duration">
            <p class="flex items-center space-x-2"
                *ngFor="let desc of list_all_plan[plan_package+'_'+plan_duration].plan_features">
                <span class="material-icons text-green-500 text-lg">check</span>
                <span class="text-gray-700 font-normal">{{desc}}</span>
            </p>
        </ng-container>
    </div>
</div>